import React, { Component } from "react";
import Navigation from "../Navigation";
import GC from "../overview/GC1";
import GV from "../overview/GV1";
import GBEagle from "../overview/GBEagle";
import GSignal from "../overview/GTSignal";
import GNivel from "../overview/GNivel";
import axios from "axios";
import { withRouter } from "react-router-dom";
import https from "https";
const instance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
});
export class Graficas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lecturas: [],
      fecha1: "",
      fecha2: "",
      sitio: "",
      nombre: "",
      contador: 1,
      rxTipo: "",
      nmxTipo: "",
    };
  }

  fecha1(event) {
    this.setState({ fecha1: event.target.value });
  }
  fecha2(event) {
    this.setState({ fecha2: event.target.value });
  }
  async cargarlecturas() {
    const res = await instance.get(
      process.env.REACT_APP_URL_MACHOTE +
        "lecturas1/fechas/" +
        this.state.sitio +
        "/" +
        this.state.fecha1 +
        "/" +
        this.state.fecha2
    );
    this.setState({ lecturas: res.data });
  }

  async componentDidMount() {
    var query = window.location.href;
    var sit = query.split("/");
    this.setState({ sitio: sit[5] });
    var res = await instance.get(
      process.env.REACT_APP_URL_MACHOTE + "lecturas1/mes/" + sit[5]
    );
    this.setState({ lecturas: res.data });
    res = await instance.get(
      process.env.REACT_APP_URL_MACHOTE + "sitios/" + sit[5]
    );

    this.setState({
      nombre: res.data[0].nombre,
      rxTipo: res.data[0]?.rxTipo ? res.data[0].rxTipo : null,
      nmxTipo: res.data[0]?.nmxTipo ? res.data[0].nmxTipo : null,
    });
    this.interval = setInterval(async () => {
      if (
        localStorage.getItem("the_main_app" + process.env.REACT_APP_COOKIE) ==
        null
      ) {
        this.props.history.push("/");
      }
    }, 1000);
  }

  render() {
    return (
      <div id="my-page">
        <div className="main-container bg-img">
          <Navigation activo="graficas" />
          <section className="widget-sec">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12 page-title">
                  <h2>{this.state.nombre}</h2>
                </div>
              </div>
              <div className="content-area">
                <div className="row">
                  <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                    <h3>Revisa por fechas los gráficos</h3>
                    <form>
                      <div className="row">
                        <div className="col-md-4 mb-3">
                          <input
                            id="fecha1"
                            onChange={this.fecha1.bind(this)}
                            type="date"
                            className="form-control"
                            placeholder="Fecha Inicial"
                            aria-label="Fecha Inicial"
                            aria-describedby="button-addon4"
                          ></input>
                        </div>
                        <div className="col-md-4 mb-3">
                          <input
                            id="fecha2"
                            onChange={this.fecha2.bind(this)}
                            type="date"
                            className="form-control"
                            placeholder="Fecha Final"
                            aria-label="Fecha fianl"
                            aria-describedby="button-addon4"
                          ></input>
                        </div>
                        <div className="col-md-4 mb-3">
                          <button
                            className="btn  btn-primary"
                            type="button"
                            onClick={this.cargarlecturas.bind(this)}
                          >
                            Cargar Lecturas
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="about-sec-2">
                  <div className="row">
                    <GC
                      lecturas={this.state.lecturas}
                      contador={this.state.contador}
                    />
                    <GV
                      lecturas={this.state.lecturas}
                      contador={this.state.contador}
                    />
                    {this.state.nmxTipo === "QA" && (
                      <GNivel
                        lecturas={this.state.lecturas}
                        contador={this.state.contador}
                      />
                    )}
                    {this.state.rxTipo !== "sat" && (
                      <>
                        <GBEagle
                          lecturas={this.state.lecturas}
                          contador={this.state.contador}
                        />
                        <GSignal
                          lecturas={this.state.lecturas}
                          contador={this.state.contador}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(Graficas);
