import React, { Component } from "react";
import Navigation from "../Navigation";
import axios from "axios";
import { withRouter } from "react-router-dom";
import https from "https";
const instance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
});
export class Informacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lecturas: [
        {
          sitio: [
            {
              _id: "-",
              idEquipo: "-",
              idMedidor: "-",
              nombre: "-",
              x: 0,
              y: 0,
              z: 0,
              cantidad_equipos: "-",
              UND: "UND",
              min: 0,
              max: 0
            }
          ]
        },
        {
          equipo: [
            {
              _id: "-",
              tipo: "-",
              modelo: "-",
              firmware: "-",
              marca: "-",
              numerotel: "-",
              imei: 0
            }
          ]
        },
        {
          equipom: [
            {
              _id: "-",
              tipo: "-",
              marca: "-",
              modelo: "-",
              protocolo: "-"
            }
          ]
        }
      ],
      sitioid: ""
    };
  }

  async componentDidMount() {
    var query = window.location.href;
    var sit = query.split("/");
    const res = await instance.get(
      process.env.REACT_APP_URL_MACHOTE + "datos/" + sit[5]
    );
    console.log(res.data[1].equipo[0])
    this.setState({ lecturas: res.data });
    this.setState({ sitioid: sit[5] });
    this.interval = setInterval(async () => {
      if (
        localStorage.getItem("the_main_app" + process.env.REACT_APP_COOKIE) ==
        null
      ) {
        this.props.history.push("/");
      }
    }, 1000);
  }

  render() {
    return (
      <div id="my-page">
        <div className="main-container bg-img">
          <Navigation activo="info" />
          <section className="widget-sec">
            <div className="container">
              <div className="content-area">
                <div className="about-sec-2">
                  <div className="row">
                    <div className="col-lg-4 col-md-12">
                      <div className="title-sec">
                        <h3>{this.state.lecturas[0].sitio[0].nombre}</h3>
                      </div>
                      <div className="detail-sec">
                        <div className="data-table">
                          <table className="table table-borderless">
                            <tbody>
                              <tr>
                                <td>Nombre del sitio</td>
                                <td>
                                  {this.state.lecturas[0].sitio[0].nombre}
                                </td>
                              </tr>

                              <tr>
                                <td>Rango de Caudal:</td>
                                <td>
                                  Min: {this.state.lecturas[0].sitio[0].min}
                                  <br />
                                  Max: {this.state.lecturas[0].sitio[0].max}
                                </td>
                              </tr>
                              <tr>
                                <td>Cantidad de Equipos:</td>
                                <td>
                                  {
                                    this.state.lecturas[0].sitio[0]
                                      .cantidad_equipos
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="title-sec">
                        <h3>Datos del Equipo de Medición</h3>
                      </div>
                      <div className="detail-sec">
                        <div className="data-table">
                          <table className="table table-borderless">
                            <tbody>
                              <tr>
                                <td>Tipo de Equipo:</td>
                                <td>
                                  {this.state.lecturas[1].equipo[0].tipo}
                                </td>
                              </tr>
                              <tr>
                                <td>Marca del Equipo:</td>
                                <td>
                                  {this.state.lecturas[1].equipo[0].marca}
                                </td>
                              </tr>
                              <tr>
                                <td>Modelo del Equipo:</td>
                                <td>
                                  {this.state.lecturas[1].equipo[0].modelo}
                                </td>
                              </tr>
                              <tr>
                                <td>Protocolo de Comunicación:</td>
                                <td>
                                  {this.state.lecturas[1].equipo[0].protocolo}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="title-sec">
                        <h3>Datos del Equipo Transmisor</h3>
                      </div>
                      <div className="detail-sec">
                        <div className="data-table">
                          <table className="table table-borderless">
                            <tbody>
                              <tr>
                                <td>Modelo de Equipo:</td>
                                <td>
                                  {this.state.lecturas[1].equipo[0].modelo}
                                </td>
                              </tr>
                              <tr>
                                <td>Marca del Equipo:</td>
                                <td>
                                  {this.state.lecturas[1].equipo[0].marca}
                                </td>
                              </tr>
                              <tr>
                                <td>Firmware del Equipo:</td>
                                <td>
                                  {this.state.lecturas[1].equipo[0].firmware}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(Informacion);
