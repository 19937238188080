import React, { Component } from "react";
import axios from "axios";
import https from "https";
const instance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
});
export class BuscarSitio extends Component {
  state = {
    sitios2: [],
    sitio: ""
  };

  async componentDidMount() {
    //pedir sitios al servidor
    const res = await instance.get(
      process.env.REACT_APP_URL_MACHOTE + "sitios/nuevo/"+process.env.REACT_APP_CLIENTE+"/"
    );
    console.log(res.data);
    this.setState({ sitios2: res.data });
  }

  valorSelect(event) {
    var sp = event.target.value.split(",");
    //console.log(sp[0]);
    this.setState({ sitio: sp[0] });
  }

  async onClick() {
    console.log(
      process.env.REACT_APP_URL_MACHOTE + "normac/" + this.state.sitio
    );
    const res = await instance.get(
      process.env.REACT_APP_URL_MACHOTE + "normac/" + this.state.sitio
    );

    this.props.recibirDatosP(res.data[0]);
  }

  render() {
    return (
      <div className="row">
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <label className="input-group-text" htmlFor="inputGroupSelect01">
              Sitios
            </label>
          </div>
          <select
            className="custom-select"
            id="inputGroupSelect01"
            onChange={this.valorSelect.bind(this)}
          >
            <option defaultValue>Elije un sitio...</option>
            {this.state.sitios2.map((sitio) => (
              <option
                key={sitio._id}
                multiple={true}
                value={[sitio.idEquipo, sitio.tipo]}
              >
                {sitio.nombre}
              </option>
            ))}
          </select>
          <div className="input-group-append">
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={this.onClick.bind(this)}
            >
              Cargar Sitio
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default BuscarSitio;
